/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 14:05:28
 * @Description:实力瘦联合创始人团队销售折扣业绩
 * @FilePath: /frontend/src/pages/rebate/shilishou/co-founder/team-purch/index.js
 */
import CompRebateLayoutOne from "@/components/rebate/comp-rebate-layout-one/index.vue";
import rebateMixin from "@/mixins/shilishou-rebate";
import { getCoFounderTeamPurch } from "@/service/rebate/shilishouRebate";
import RebateTable from "@/components/rebate/rebate-table/index.vue";

export default {
  name: "share-holders-team-purch",
  mixins: [rebateMixin],
  components: {
    CompRebateLayoutOne,
    RebateTable,
  },

  data() {
    return {
      // 本人配置列
      columns: [
        {
          label: "姓名",
          key: "name",
          render(val, row) {
            return val;
            // return `${val}<span class="small">${row.recommendLevel}</span>`;
          },
        },
        {
          label: "团购金额",
          isRmb: true,
          key: "teamTotalSale",
        },
        {
          label: "销售折扣",
          isRmb: true,
          key: "teamDiscount",
        },
        {
          label: "推荐奖",
          isRmb: true,
          isNegative: true,
          key: "recommendAward",
        },
      ],
    };
  },

  computed: {
    //  列表数据
    datasource() {
      const { rebateInfo, userInfo } = this;
      if (rebateInfo) {
        const {
          teamTotalSale,
          teamDiscount,
          recommendAward,
          uniteTeamList = [],
        } = rebateInfo;
        const { name, id: userId } = userInfo || {};


        uniteTeamList.unshift({
          teamTotalSale,
          teamDiscount,
          recommendAward,
          name,
          recommendLevel: "0",
          userId
        });

        return uniteTeamList;
      } else {
        return [];
      }
    },
    //  团队总人数（不含本人）
    account() {
      const { datasource } = this;
      return datasource.length - 1;
    },
  },

  methods: {
    //  初始化页面
    init() {
      const { startTime, endTime, agentId } = this;
      getCoFounderTeamPurch({
        startTime,
        endTime,
        userId: agentId,
      }).then((data) => {
        this.rebateInfo = data;
      });
    },

    showDetail(row) {
      this.$router.push({
        name: "shilishou-team-purch-detail",
        query: { ...this.$route.query, agentId: row.userId },
      });
    },
  },
};
